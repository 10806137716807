#skills {
    height: 100vh;
    background: #fff;
    padding-top: 90px;
    padding-bottom: 72px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    row-gap: 100px;
 }
 
 #skills a, #resume a:visited  { color: #11ABB0; }
 #skills a:hover, #resume a:focus { color: #313131; }
 
 #skills h1 {
    color: #222;
    font: 22px/28px 'opensans-bold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
 }
 #skills h1 span {
    border-bottom: 3px solid #11ABB0;
    padding-bottom: 6px;
 }
 #skills h3 {
    font: 25px/30px 'opensans-bold', sans-serif;
 }
 
 #skills .header-col { padding-top: 9px; }
 #skills .main-col { padding-right: 10%; }
 
  
  span {
    text-align:center;
  }

  section .scrolldown a {
    position: relative;
    bottom: 15%;
    left: 50%;
    margin-left: -29px;
    color: #222;
    display: block;
    height: 42px;
    width: 42px;
    font-size: 42px;
    line-height: 42px;
    color: #fff;
    border-radius: 100%;
 
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
 }
 
  .skillsTitle{
     display: flex;
     justify-content: center;
     padding-bottom: 10px;
  }
  .skillsDescription{
    display: flex;
    justify-content: center;
 }

 .skills-container {
     display: flex;
     flex-direction: column;
 }

 .flex-grid {
    justify-content: center;
    margin-top: 3%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  .col {
    display: flex;
    color: #222;
    justify-content: center;
    flex-direction: column;
    flex: 1 0 21%; /* explanation below */
    margin: 5px;
    font-weight: 600;
    height: 125px;
  }
  .col i {
    text-align:center;
    font-size: 5em
  }

  
@media screen and (max-width: 700px) {
  #skills {
    height: 100%;
    font-size: 12px;
  }
  #skills h1 {
      color: #222;
      font: 16px/24px 'opensans-bold', sans-serif;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    p {
      font: 12px/16px 'opensans-bold', sans-serif;
    }
   .skillsDescription {
    text-align: center;
   }
   
}