* {
  margin: 0;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
}

body {
   background-color: #222;
   font-family: 'opensans-regular', sans-serif;
   font-weight: normal;
   font-size: 15px;
   line-height: 30px;
	color: #222;

   -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
	-webkit-text-size-adjust: 100%;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #fff;
}

*,
*:before,
*:after {
   -moz-box-sizing: border-box;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
}

html {
   font-size: 62.5%;
   -webkit-font-smoothing: antialiased;
}

/** styles.css */

.smooth-image {
   transition: opacity 1s;
 }
 .image-visible {
   opacity: 1;
 }
 .image-hidden {
   opacity: 0;
 }

