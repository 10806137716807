footer {
    background-color: #222;
    padding-top: 48px;
    padding-bottom: 36px;
    color: #303030;
    font-size: 14px;
    text-align: center;
    position: relative;
 }
 
 footer a, footer a:visited { color: #525252; }
 footer a:hover, footer a:focus { color: #fff; }
 
 /* copyright */
 footer .copyright {
     margin: 0;
     padding: 0;
  }
 footer .copyright li {
     display: inline-block;
     margin: 0;
     padding: 0;
     line-height: 24px;
 }
 .ie footer .copyright li {
    display: inline;
 }
 footer .copyright li:before {
     content: "\2022";
     padding-left: 10px;
     padding-right: 10px;
     color: #095153;
 }
 footer .copyright  li:first-child:before {
     display: none;
 }
 
 /* social links */
 footer .social-links {
    margin: 18px 0 30px 0;
    padding: 0;
    font-size: 30px;
 }
 footer .social-links li {
     display: inline-block;
     margin: 0;
     padding: 0;
     margin-left: 42px;
     color: #F06000;
 }
 
 footer .social-links li:first-child { margin-left: 0; }
 
 /* Go To Top Button */
 #go-top {
     position: absolute;
     top: -24px;
    left: 50%;
    margin-left: -30px;
 }
 #go-top a {
     text-decoration: none;
     border: 0 none;
     display: block;
     width: 60px;
     height: 60px;
     background-color: #525252;
 
     -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
 
    color: #fff;
    font-size: 21px;
    line-height: 60px;
      border-radius: 100%;
 }
 #go-top a:hover { background-color: #0F9095; }