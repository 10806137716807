#portfolio {
    background: #ebeeee;
    min-height: 90vh;
    padding-top: 90px;
    padding-bottom: 60px;
 }
 #portfolio h1 {
    font: 15px/24px 'opensans-semibold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 10%;
    color: #222;
 }
 #portfolio h1 span {
    border-bottom: 3px solid #11ABB0;
    padding-bottom: 6px;
 }
 #portfolio h1 {
    font: 22px/28px 'opensans-bold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
 }
 /* Portfolio Content */
 img {
    height: 220px;
    width: auto;
 }

  section .scrolldown a {
    position: relative;
    bottom: 10%;
    left: 50%;
    margin-left: -29px;
    color: #222;
    display: block;
    height: 42px;
    width: 42px;
    font-size: 42px;
    line-height: 42px;
    color: #fff;
    border-radius: 100%;
 
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
 }
 .item-wrap {
     height: 220px;
     width: 100%;
 }

 .portfolio-item .item-wrap {
    background: #fff;
    overflow: hidden;
    position: relative;
 
    -webkit-transition: all 0.3s ease-in-out;
     -moz-transition: all 0.3s ease-in-out;
     -o-transition: all 0.3s ease-in-out;
     -ms-transition: all 0.3s ease-in-out;
     transition: all 0.3s ease-in-out;
 }
 .portfolio-item .item-wrap a {
    display: block;
    cursor: pointer;
 }
 
 /* overlay */
 .portfolio-item .item-wrap .overlay {
     position: absolute;
     height: 220px;
     width: 100%;
     left: 0;
     top: 0;
     opacity: 0;
     -moz-opacity: 0;
     filter:alpha(opacity=0);
 
    -webkit-transition: opacity 0.3s ease-in-out;
     -moz-transition: opacity 0.3s ease-in-out;
     -o-transition: opacity 0.3s ease-in-out;
     transition: opacity 0.3s ease-in-out;
 
    background: url(../../Assets/overlay-bg.png) repeat;
 }
 .portfolio-item .item-wrap .link-icon {
    display: block;
    color: #fff;
    height: 30px;
    width: 30px;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
 
    opacity: 0;
     -moz-opacity: 0;
     filter:alpha(opacity=0);
 
    -webkit-transition: opacity 0.3s ease-in-out;
     -moz-transition: opacity 0.3s ease-in-out;
     -o-transition: opacity 0.3s ease-in-out;
     transition: opacity 0.3s ease-in-out;
 
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
 }
 .portfolio-item .item-wrap img {
    vertical-align: bottom;
 }
 .portfolio-item .portfolio-item-meta { padding: 18px }
 .portfolio-item .portfolio-item-meta h5 {
    font: 14px/21px 'opensans-bold', sans-serif;
    color: #fff;
 }
 .portfolio-item .portfolio-item-meta p {
    font: 12px/18px 'opensans-light', sans-serif;
    color: #c6c7c7;
    margin-bottom: 0;
 }
 
 /* on hover */
 .portfolio-item:hover .overlay {
     opacity: 1;
     -moz-opacity: 1;
     filter:alpha(opacity=100);
 }
 .portfolio-item:hover .link-icon {
    opacity: 1;
     -moz-opacity: 1;
     filter:alpha(opacity=100);
 }
 
 .portfolio-container {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
 }

 .project-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
 }
 .columns.portfolio-item {
    display: flex;
 }

 #portfolio .portfolio-header {
    margin-bottom: 40px;
 }

 @media (max-width: 900px) {
   .project-container {
     flex-direction: column;
     align-items: center;
     height: 850px;
   }
   img, .item-wrap, .overlay {
      height: 220px;
      width: 300px;
   }

   #portfolio h1 {
         color: #222;
         font: 16px/24px 'opensans-bold', sans-serif;
         text-transform: uppercase;
         letter-spacing: 1px;
      }
      p {
        font: 12px/16px 'opensans-bold', sans-serif;
      }
      .col i {
         text-align: center;
         font-size: 4em; 
     }
     .col {
        height: 80px;
        flex: 33%;
     }
   }