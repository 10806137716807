   #about {
      height: 100vh;
      background: #ebeeee;
      padding-top: 96px;
      padding-bottom: 66px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      row-gap: 100px;

   }
   #about a, #about a:visited  { color: #fff; }
   #about a:hover, #about a:focus { color: #11ABB0; }
   
   #about h2 {
      color: #222;
      margin-bottom: 15px;
   }
   #about h2 p {
      line-height: 40px;
      color: #222;
      font: 16px/22px 'opensans-bold', sans-serif;
   }
   
   #about .social li a { 
      color: #222; 
      font-size: 2vw;
      margin: 10px;
   }
   
   #about .profile-pic {
      position: relative;
      width: 70%;
      height: auto;
      border-radius: 10px;
      box-shadow: 0 0 8px -4px rgb(0 0 0 / 50%);
   }
   #about .contact-details { width: 60%; }

   section .scrolldown a {
      position: relative;
      bottom: 10%;
      left: 50%;
      margin-left: -29px;
      color: #222;
      display: block;
      height: 42px;
      width: 42px;
      font-size: 42px;
      line-height: 42px;
      color: #fff;
      border-radius: 100%;
   
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
   }

   ul.social {
      padding-left: 0px;
      padding-top: 10px;
   }
   
   .social {
      display: flex;
      justify-content: center;
      list-style-type: none;
      padding-top: 5px;
   }

   .about-container {
      display: flex;
      align-items: center;
      justify-content: center;
      
   }
   .aboutContent-container {
      width: 100%;
      display: flex;
      margin-left: 10%;
      margin-right: 10%;
      justify-content: space-between;
   }
   .aboutContent-container {
      width: 100%;
      display: flex;
      margin-left: 10%;
      margin-right: 10%;
      justify-content: space-between;
   }

   .leftAbout {
      width: 50%;
      margin-top: 20px;
   }

   .rightAbout {
      width: 50%;
      padding-left: 20px;
   }

 .profilepic-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 }

 .resumeButton {
     box-shadow:inset 0px 1px 0px 0px #54a3f7;
     background:linear-gradient(to bottom, #007dc1 5%, #0061a7 100%);
     background-color:#007dc1;
     border-radius:3px;
     border:1px solid #124d77;
     display:inline-block;
     cursor:pointer;
     color:#ffffff;
     font-family:Arial;
     font-size:13px;
     padding:8px 12px;
     text-decoration:none;
     text-shadow:0px 1px 0px #154682; 
 }
 .resumeButton-container {
    display: flex;
    padding-bottom: 50px;
 }

 .resumeButton:hover {
     background:linear-gradient(to bottom, #0061a7 5%, #007dc1 100%);
     background-color:#0061a7;
 }

 .resumeButton:active {
     position:relative;
     top:1px;
 }

 #about h2 {
   color: #222;
   font: 22px/28px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 1px;
   text-decoration: underline;
   text-decoration-color: #11ABB0;
   text-decoration-thickness: 3px;
   padding-bottom: 6px;
 }


 @media screen and (max-width: 700px) {
   .aboutContent-container {
     justify-content: center;
     align-items: center;
     flex-direction: column;
     width: 80%;
   }
   .profilepic-container {
      width: 100%;
      align-items: center;
   }
   #about {
      height: 100%;
   }
   .rightAbout {
      width: 100%;
   }
   #about .profile-pic {
      width: 200px;
      height: auto;
   }
   .rightAbout, .leftAbout {
      width: 100%;
      flex-direction: row;
   }
   #about .social li a { 
      color: #222; 
      font-size: 4vw;
   }
   p {
      font: 12px/16px 'opensans-bold', sans-serif;
    }
 }