header {
    height: 110vh;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 100%;
    min-height: 500px;
     -webkit-background-size: cover !important;
    text-align: center;
    overflow: hidden;
    transition: .4s;
 }

 .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
 }
 
 /* vertically center banner section */
 header .banner {
    display: none;
    vertical-align: middle;
    margin: 0 auto;
    width: 50%;
    padding-bottom: 30px;
    padding-top: 30px;
    text-align: center;
    background-color: rgb(37, 37, 37); /* Needed for IEs */
    opacity: .7;
  
    -moz-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
    -webkit-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
    box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=3,MakeShadow=true,ShadowOpacity=0.30);
    -ms-filter: "progid:DXImageTransform.Microsoft.Blur(PixelRadius=3,MakeShadow=true,ShadowOpacity=0.30)";
    zoom: 1;
 }
 header .banner.unhide {
   display: block;
 }
 
 header .banner-text { width: 100%; border-radius: 10px; padding-bottom: 10px; }
 header .banner-text h1 {
    font-size:6vw;
    color: #fff;
    letter-spacing: -2px;
    margin: 0 auto 18px auto;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, .8);
 }
 header .banner-text h3 {
    font: 18px/1.9em 'librebaskerville-regular', serif;
    color: #A8A8A8;
    margin: 0 auto;
    width: 70%;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, .5);
 }
 header .banner-text h3 span,
 header .banner-text h3 a {
    color: #fff;
 }
 header .banner-text hr {
    width: 60%;
    margin: 18px auto 24px auto;
    border-color: #2F2D2E;
    border-color: rgba(150, 150, 150, .1);
 }
 
 /* header social links */
 header .social {
    margin: 24px 0;
    padding: 0;
    font-size: 30px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, .8);
 }
 header .social li {
    display: inline-block;
    margin: 0 15px;
    padding: 0;
 }
 header .social li a { color: #fff; }
 header .social li a:hover { color: #11ABB0; }
 
 /* scrolldown link */
 header .scrolldown a {
    position: absolute;
    bottom: 15%;
    left: 50%;
    margin-left: -29px;
    color: #fff;
    display: block;
    height: 42px;
    width: 42px;
    font-size: 42px;
    line-height: 42px;
    color: #fff;
    border-radius: 100%;
 
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
 }
 header .scrolldown a:hover { color: #11ABB0; }

 .navbar {
   display: none;
   position: fixed;
   height: 52px;
   top: 0;
   left: 0;
   right: 0;
   text-transform: uppercase;
   letter-spacing: 2.5px;
   transition: .4s;
   z-index: 99999;
}

.banner.unhide {
   display: block;
 }

 .navbar.unhide {
   display: block;
 }


.scroll {    
  left: 0;
  margin: auto;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 0 60px 0 rgb(0 0 0 / 7%);
  z-index: 1030;
  padding: 3px 0;
  color: #fff;
  background: #343a40;
  padding: 0;
  opacity: .9;
}

div.m-auto.navbar-nav {
   width: 400px;
    display: flex;
    justify-content: space-around;
}

 a.mr-auto.navbar-brand,
 .navbar-light .navbar-nav .nav-link {
    color: #fff;
    font-weight: 600;
 }

 a.nav-link {
   color: #fff
 }
 
 .smooth-image-wrapper {
    position: absolute;
    object-fit: cover;
 }

 .smooth-image.img-visible {
    object-fit: cover !important;
 }

 header .scrolldown a:hover { color: #11ABB0; }

 @media screen and (max-width: 1000px) {

   header .banner {
      height: 260px;
   }
   .Typewriter {
      height: 48px;
   }
   .topnav.responsive {position: relative;}
   .topnav.responsive .icon {
     position: absolute;
     right: 0;
     top: 0;
   }
   .topnav.responsive a {
     float: none;
     display: block;
     text-align: left;
   }
   .navbar.navbar-expand-lg.navbar-light{
      background-color: #343a40;
      display: flex;
      justify-content: flex-end;
   }
   .navbar {
      padding: 0px;
      opacity: .9;
   }
   
   div.m-auto.navbar-nav {
      width: 100%;
      display: flex;
      justify-content: space-around;
      background-color: #343a40;
   }
   .navbar-light .navbar-toggler {
      position: absolute;
      color: #fff;
      margin-right: 10px;
   }
 }

 .navbar-dark .navbar-nav .nav-link {
   color: #fff;
}

 a.nav-link.activeLink {
   color:rgb(212, 120, 0) !important;
}
